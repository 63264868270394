<script>
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheInnerToolbar from "@/components/organisms/TheInnerToolbar.vue";
import AppStepper from "../atoms/AppStepper.vue";
import billingCreateForm from "@/constants/form/billing-create-form";

export default {
  name: "BillingCreateTemplate",
  components: { AppStepper, AppDialog, TheInnerToolbar },
  data: () => ({
    step: 1,
    dialog: false,
    form: {},
    formKeys: {
      2: "3",
    },
    stepList: JSON.parse(JSON.stringify(billingCreateForm)),
  }),
  props: {
    memberName: {
      type: String,
      default: "",
    },
  },
  created() {
    if (this.hasBilling && this.step === 1) {
      this.step = 2;
    }
  },
  computed: {
    hasBilling: function () {
      return (
        this.$store.state.billing.billingList.filter(
          (billing) => billing.type != "expense"
        ).length > 0
      );
    },
  },
  methods: {
    changeStep(payload) {
      this.step = payload;
    },
    handleChange(payload) {
      let keys = Object.keys(this.stepList);
      keys.forEach((key) => {
        if (this.formKeys[key] != undefined) {
          let fieldKeys = Object.keys(
            this.stepList[key][this.formKeys[key]].form
          );
          fieldKeys.forEach((field) => {
            this.stepList[key][this.formKeys[key]].form[field].value =
              payload[this.stepList[key][this.formKeys[key]].name][field];
          });
        }
      });
    },
    cancelConfirm() {
      this.dialog = false;
      this.$emit("cancel");
    },
    cancelCancel() {
      this.dialog = false;
    },
    cancel() {
      this.dialog = true;
    },
    submit(payload) {
      this.$emit("submit", payload["billing-create"]);
    },
  },
};
</script>

<template>
  <section class="inner-form section-container">
    <the-inner-toolbar
      title="Adicionar Mensalidade"
      :isForm="true"
      @cancel="cancel"
    />

    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="formKeys"
      @change="handleChange"
      @cancel="cancel"
      @submit="submit"
    />
    <app-dialog
      :dialog="dialog"
      title="Confirmação"
      text="Tem certeza que dejeja cancelar? Os dados inseridos serão perdidos."
      buttonCancel="Voltar"
      buttonConfirm="Confirmar"
      @clickConfirm="cancelConfirm"
      @clickCancel="cancelCancel"
    />
  </section>
</template>

<style lang="scss" scoped>
.form-container {
  height: 100%;
  width: 100%;
}
</style>
