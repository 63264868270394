<script>
import BillingCreateTemplate from "../templates/BillingCreateTemplate.vue";
import { mapGetters } from "vuex";

export default {
  components: { BillingCreateTemplate },
  name: "BillingCreatePage",
  data: () => ({
    form: {},
  }),
  props: {
    id: String,
  },
  computed: {
    billedUser: function () {
      return this.$store.state.user.users.filter(
        (user) => user.id === this.id
      )[0];
    },
    ...mapGetters({ currentAcademy: "academy/currentAcademy" }),
  },
  methods: {
    async submit(payload) {
      let newBilling = {
        date: payload.date,
        reference: payload.reference,
        method: payload.method,
        price: payload.price,
        memberId: this.id,
        type: "income",
        academyId: this.currentAcademy.id,
      };
      await this.$store.dispatch("billing/create", newBilling);
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <billing-create-template
    :memberName="billedUser.name"
    @cancel="cancel"
    @submit="submit"
  />
</template>
