import paymentMethods from "@/constants/payment-methods";
import moment from "moment";

const billingCreateForm = {
  1: {
    0: {
      type: "title",
      title: "Vamos Registrar uma nova mensalidade!",
    },
    1: {
      subtitle: "Aqui você irá cadastrar os pagamentos feitos por seus alunos.",
      type: "subtitle",
    },
    2: {
      type: "subtitle",
      subtitle: "Esses dados podem ser alterados mais tarde.",
    },
    3: {
      type: "image",
      image: "no-members",
    },
    4: {
      type: "buttons",
      buttons: ["next"],
    },
  },
  2: {
    0: {
      type: "title",
      title: "Adicionar Mensalidade",
    },
    2: {
      type: "subtitle",
      subtitle: "Insira os dados da mensalidade abaixo.",
    },
    3: {
      type: "form",
      name: "billing-create",
      form: {
        reference: {
          value: moment().format("YYYY-MM"),
          label: "Mês de Referência",
          name: "reference",
          inputType: "date",
          dateType: "month",
        },
        date: {
          value: moment().format("YYYY-MM-DD"),
          label: "Data do Pagamento",
          name: "date",
          inputType: "date",
        },
        method: {
          value: "pix",
          inputType: "select",
          items: paymentMethods,
          label: "Método de Pagamento",
          name: "method",
        },
        price: {
          value: "",
          label: "Valor",
          inputType: "money",
          name: "price",
        },
      },
    },
    4: {
      type: "buttons",
      buttons: ["previous", "next", "submit"],
    },
  },
};

export default billingCreateForm;
